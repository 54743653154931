.logcontainer {
    position: absolute;
    top: 0;
    width: 100%;
    overflow: auto;
    background-color: white;
    bottom: 49px;
}

@media only screen and (max-width: 360px) {
    .log,
    .inputarea {
        max-width: 100%;
    }
}

@media only screen and (min-width: 361px) {
    .log,
    .inputarea {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
    }
}

.inputfooter {
    position: fixed;
    left: 0;
    bottom: 0;
    height: 50px;
    width: 100%;
    background-color: #F8F8F8;
    border-top: 1px solid #E7E7E7;
}

.log {
    position: absolute;
    bottom: 0px;
    right: 0px;
    left: 0px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100%;
}

.form-control:focus,
.btn:focus {
    border-color: #E7E7E7;
    box-shadow: none;
    -webkit-box-shadow: none;
}

.inputbox {
    width: calc(100% - 75px);
    float: left;
    margin-top: 5px;
    margin-left: 5px;
}

.inputarea .inputbutton {
    float: right;
    height: 100%;
    width: 60px;
    margin-top: 5px;
    margin-right: 5px;
}

.line {
    margin: 5px;
    font-family: Verdana;
    font-size: 0.9em;
    padding: 0.5em;
    clear: both;
    max-width: 80%;
    text-shadow: none;
    white-space: normal;
}

.botline {
    position: relative;
    background-color: #E5E5EA;
    color: Black;
    float: left;
    border-radius: 10px;
    padding: 10px 20px;
}

.userline {
    position: relative;
    background-color: #0B93F6;
    color: White;
    float: right;
    text-align: left;
    border-radius: 10px;
    padding: 10px 20px;
}

.userline::before {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: -2px;
    right: -7px;
    height: 20px;
    /*border-right: 20px solid #0B93F6;*/
    border-bottom-left-radius: 16px 14px;
    -webkit-transform: translate(0, -2px);
    transform: translate(0, -2px);
}

.userline::after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: -2px;
    right: -56px;
    width: 26px;
    height: 20px;
    /*background: white;*/
    border-bottom-left-radius: 10px;
    -webkit-transform: translate(-30px, -2px);
    transform: translate(-30px, -2px);
}

.botline::before {
    content: "";
    position: absolute;
    z-index: 2;
    bottom: -2px;
    left: -7px;
    height: 20px;
    /*border-left: 20px solid #E5E5EA;*/
    border-bottom-right-radius: 16px 14px;
    -webkit-transform: translate(0, -2px);
    transform: translate(0, -2px);
}

.botline::after {
    content: "";
    position: absolute;
    z-index: 3;
    bottom: -2px;
    left: 4px;
    width: 26px;
    height: 20px;
    /*background: white;*/
    border-bottom-right-radius: 10px;
    -webkit-transform: translate(-30px, -2px);
    transform: translate(-30px, -2px);
}

.media-bubble {
    padding: 0;
}

.suggestline {
    color: Black;
    padding: 10px;
    max-width: 100%;
    white-space: nowrap;
    overflow-x: auto;
}

.suggestbutton {
    border: 1px solid #1CAAE2;
    border-radius: 20px;
    background-color: #FFFFFF;
    font-family: HyundaiSansText-Regular, Arial, Helvetica, sans-serif;
    color: #1CAAE2;
    font-size: 13px;
    line-height: 16px;
    padding: 10px;
}

.templateline {
    color: Black;
    max-width: 100%;
    white-space: nowrap;
    overflow-x: auto;
    padding: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.templatebutton {
    color: #1CAAE2;
    font-family: HyundaiSansText-Regular, Arial, Helvetica, sans-serif;
    background-color: white;
    border: none;
    border-top: 2px solid #EBEBEB;
    border-left: 1px solid #EBEBEB;
    border-right: 1px solid #EBEBEB;
    line-height: 35px;
    height: 35px;
    font-size: 17px;
}

.templateimage {
    background-color: white;
    border: 1px solid #EBEBEB;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    width: 100%;
}

.templatetitle {
    background-color: white;
    border-left: 1px solid #EBEBEB;
    border-right: 1px solid #EBEBEB;
    font-weight: bolder;
    font-size: 18px;
    color: #083765;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    line-height: 23px;
}

.templatesubtitle {
    background-color: white;
    border-left: 1px solid #EBEBEB;
    border-right: 1px solid #EBEBEB;
    font-size: 12px;
    color: #868686;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 15px;
    white-space: normal;
}

.divider {
    padding: 5px;
    clear: both;
    display: flex;
    align-items: center;
    font-size: 0.8em;
}

.dividerline {
    border-bottom: 2px solid lightgray;
    width: 100%;
}

.dividertext {
    padding: 0 10px 0 10px;
    white-space: nowrap;
}

.dividerpad {
    width: 100%;
}