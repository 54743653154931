body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

tr:hover .bt {
    margin-left: 10px;
    opacity: 100;
}

tr .bt {
    margin-left: 10px;
    opacity: 0;
}

.heading {
    font-size: 1rem;
    padding-bottom: 10px;
}

.paragraph {
    font-size: 0.8rem;
}